import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {AppShell, Hero} from '../components/custom'
import {ContentContainer} from '../components/base'
import WpContent, {DefaultWrapper} from '../components/base/WpContent/lazy'
import SSRSuspense from '../components/custom/SSRSuspense/SSRSuspense'
import {getLangToSlug} from '../utils'


const Page = ({data, pageContext: {lang}}) => {
  const {title, featuredImage, seo, translations, uri, content} = data.wpPage
  const fallBack = (<DefaultWrapper>{content}</DefaultWrapper>)

  return (
    <AppShell title={title} seo={seo} lang={lang} langToSlug={getLangToSlug({translations, uri, lang})}>
      <Hero url={featuredImage?.node?.localFile?.url} title={title} />
      <If condition={Boolean(content)}>
        <ContentContainer>
          <SSRSuspense fallback={fallBack}>
            <WpContent>
              {content}
            </WpContent>
          </SSRSuspense>
        </ContentContainer>
      </If>
    </AppShell>
  )
}

export const data = graphql`
  query BlogPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      translations {
        locale
        href
      }
      uri
      featuredImage{
        node {
          localFile {
            url
          }
        }
      }
      content
      seo {
        metaDesc
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          mediaItemUrl
        }
        twitterImage {
          mediaItemUrl
        }
      }
    }
  }
`

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default Page
